define("discourse/plugins/discourse-bcc/discourse/components/bcc-checkbox", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("creatingPrivateMessage", "targetRecipients", "targetGroups"), (_obj = {
    tagName: "",
    bccAvailable() {
      return this.currentUser.staff && this.creatingPrivateMessage && ((this.targetRecipients || "").split(",").filter(Boolean).length > 1 || this.targetGroups);
    }
  }, (_applyDecoratedDescriptor(_obj, "bccAvailable", [_dec], Object.getOwnPropertyDescriptor(_obj, "bccAvailable"), _obj)), _obj)));
});